import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import SignoutButton from "../SignoutButton";

const NavbarMain = () => {
    const auth = useSelector((state) => state.auth);
    return (
        <nav className="Navbar">
            <div className="navbar-content">
                <a className="no-deco" href="/">
                    <div className="header-container">
                        <img src="/logo.svg" alt="Logo" className="logo" />
                        <div className="title-container">
                            <h1 className="title">bcyl-x</h1>
                            <h2 className="subtitle">Belichick your line-up Stock Exchange</h2>
                        </div>
                    </div>
                </a>
                <div className="inner container is-fixed">
                    {auth.isAuthenticated ? (
                        <React.Fragment>
                            <SignoutButton />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <NavLink exact activeClassName="active" className="no-deco" to="/signin">
                                Login
                            </NavLink>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default NavbarMain;
